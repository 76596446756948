import React from 'react'
import * as styles from './herotext.module.css'

export const HeroText = ({children}) => {
  const classNames = `${styles.herotext} ${styles.content}`
  return (
    <section className={classNames}>
      {children}
    </section>
  )
}


export default HeroText;
