import React, { useState, useEffect } from 'react';
import * as styles from './sidenote.module.css'

export const Sidenote = ({id, children, long, before, after}) => {
  let sidenoteClass = `${styles.sidenote}`
  if (long) sidenoteClass += ` ${styles.long}`
  if (before) sidenoteClass += ` ${styles.before}`
  if (after) sidenoteClass += ` ${styles.after}`

  const anchorId = toAnchorId(id)
  const sidenoteId = toSidenoteId(id)
  const [isHovering, setIsHovering] = useState(false)

  useEffect(() => {
    // Update the document title using the browser API
    const sidenoteReference = document.querySelector(`#${anchorId}`);
    if (!sidenoteReference) return;

    if (isHovering) {
      sidenoteReference.classList.add(styles.highlight);
    } else {
      sidenoteReference.classList.remove(styles.highlight);
    }
  })

  return (
    <section className={styles.container}>
      <aside id={sidenoteId} 
        className={sidenoteClass}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}>
        {children}
      </aside>
    </section>
  )}

export const SidenoteAnchor = ({id, children}) => {
  const sidenoteId = toSidenoteId(id);
  const anchorId = toAnchorId(id)
  const [isHovering, setIsHovering] = useState(false)

  useEffect(() => {
    // Update the document title using the browser API
    const sidenoteReference = document.querySelector(`#${sidenoteId}`);
    if (!sidenoteReference) return;

    if (isHovering) {
      sidenoteReference.classList.add(styles.highlight);
    } else {
      sidenoteReference.classList.remove(styles.highlight);
    }
  })

  return (
    <span id={anchorId}
        className={styles.anchor}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}>
      {children}
    </span>
  );
};

const toAnchorId = (id) => `sidenote-anchor-${id}`;
const toSidenoteId = (id) => `sidenote-${id}`;
