import React from 'react'

export class ThreeJSSphere extends React.Component {
  static defaultProps = {
    animated: false,
  }

  constructor(props) {
    super(props)
    this.loaded = false
    this.parent = React.createRef()
  }

  componentDidMount() {
    this.loadSphere()
  }

  loadSphere() {
    if (!window.THREE && !this.loaded) {
      setTimeout(() => this.loadSphere(), 1000)
      return
    }

    this.loaded = true
    const el = this.parent.current
    const width = el.getBoundingClientRect().width
    const height = width

    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000)

    const renderer = new THREE.WebGLRenderer()
    renderer.setSize(width, height)
    el.appendChild(renderer.domElement)
    el.style.marginBottom = '1.5rem'

    const geometry = new THREE.SphereGeometry(15, 16, 16)
    const material = new THREE.MeshLambertMaterial({
      color: 0x00ffee,
    })
    const cube = new THREE.Mesh(geometry, material)
    scene.add(cube)

    const wireframe = new THREE.Mesh(
      new THREE.SphereGeometry(15, 16, 16),
      new THREE.MeshLambertMaterial({
        color: 0x00eeaa,
        wireframe: true,
      })
    )
    scene.add(wireframe)

    // Create a point light
    const pointLight = new THREE.PointLight(0xffffff)
    // Set its position
    pointLight.position.x = 50
    pointLight.position.y = 50
    pointLight.position.z = 100
    // Add to the scene
    scene.add(pointLight)

    camera.position.z = 40

    const { animated } = this.props
    function renderScene() {
      requestAnimationFrame(renderScene)
      if (animated) {
        cube.rotation.x += 0.005
        cube.rotation.y += 0.005
        wireframe.rotation.x += 0.005
        wireframe.rotation.y += 0.005
      }
      renderer.render(scene, camera)
    }
    renderScene()
  }

  render() {
    return <div ref={this.parent}></div>
  }
}
