import React from 'react'
import { Link } from 'gatsby'

const AgnosticLink = ({ className, href, rel, target, children , ...rest}) => {
  const external = href.startsWith('http')

  if (external) {
    return <a {...{ className, href, rel, target}} {...rest}>{children}</a>
  } else {
    return (
      <Link to={href} {...{ className, rel, target }}>
        {children}
      </Link>
    )
  }
}

export default AgnosticLink
