import React from 'react'

export class ThreeJSLine extends React.Component {
  constructor(props) {
    super(props)
    this.loaded = false
    this.parent = React.createRef()
  }

  componentDidMount() {
    this.loadLine()
  }

  loadLine() {
    if (!window.THREE && !this.loaded) {
      setTimeout(() => this.loadLine(), 1000)
      return
    }

    this.loaded = true
    const el = this.parent.current
    const width = el.getBoundingClientRect().width
    const height = width

    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000)

    const renderer = new THREE.WebGLRenderer()
    renderer.setSize(width, height)
    el.appendChild(renderer.domElement)
    el.style.marginBottom = '1.5rem'

    // 1. Define Geometry
    // The BufferGeomtry lets us a mesh, line or point geometry in a lot more detail
    const geometry = new THREE.BufferGeometry().setFromPoints([
      new THREE.Vector3(-10, 0, 0),
      new THREE.Vector3(0, 10, 0),
      new THREE.Vector3(10, 0, 0),
    ])

    // 2. Define the material to color the lines
    // The LineBasicMaterial lets us draw wire-frame geometries in a way they
    // aren't affected by light
    const material = new THREE.LineBasicMaterial({
      color: 0x0000ff,
    })

    // 3. Create a line to apply material to geometry
    // A Line is a 3D object used to render continous lines in three.js
    const line = new THREE.Line(geometry, material)
    scene.add(line)

    camera.position.set(0, 0, 20)
    camera.lookAt(0, 0, 0)

    function renderScene() {
      requestAnimationFrame(renderScene)
      renderer.render(scene, camera)
    }
    renderScene()
  }

  render() {
    return <div ref={this.parent}></div>
  }
}
