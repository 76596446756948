import ImportScript from "../../../../src/components/ImportScript.js";
import { ThreeJSCube } from "../../../../src/mdxpages/notes/three-js/ThreeJSCube.js";
import { ThreeJSLine } from "../../../../src/mdxpages/notes/three-js/ThreeJSLine.js";
import { ThreeJSSphere } from "../../../../src/mdxpages/notes/three-js/ThreeJSSphere.js";
import * as React from 'react';
export default {
  ImportScript,
  ThreeJSCube,
  ThreeJSLine,
  ThreeJSSphere,
  React
};