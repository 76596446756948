import React from 'react'

export default class ImportScript extends React.Component {
  componentDidMount() {
    const { sid, src, dataset, children, ...rest } = this.props
    this.loadScript(sid, src, dataset, rest)
  }

  loadScript(id, src, dataset, rest) {
    const script = document.createElement('script')
    script.src = src
    script.async = true
    Object.assign(script, rest)
    Object.assign(script.dataset, dataset)
    const el = document.querySelector(`#${id}`)
    //console.log(`[ImportScript] Inject script ${id} in element: `, el)
    if (el) el.appendChild(script)
  }

  render() {
    return <span id={this.props.sid}>{this.props.children}</span>
  }
}
