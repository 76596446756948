import React, { useState } from "react";
import * as styles from './vimsample.module.css'
import {useInterval} from '../../../utils/useInterval.js';

/*
*
* Check this and see if I learn how to inject an interactive code sample into this :D
* that supports highlighting and yara yara:
*
*   https://egghead.io/courses/building-websites-with-mdx-and-gatsby-161e9529
*
 * 1. Change between static sample AND interactive example (with a button)
 * 2. Add some more helpful legends (commands here, etc)
 */

export const VimSample = ({start, steps, children, language="text"}) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [isInteractive, setIsInteractive] = useState(true)
  const [currentStep, setCurrentStep] = useState(0)
  const isFinalStep = currentStep === steps.length - 1

  useInterval(() => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep+1)
    }
  }, isPlaying ? 1000 : null)

  function startStopOrReset() {
    if (!isPlaying) {
      setIsPlaying(true);
    } else if (isFinalStep) { 
      setIsPlaying(true)
      setCurrentStep(0)
    } else {
      setIsPlaying(false)
    }
  }

  return (
    <section className={styles.sample}>
      <div className={styles.controls}>
          <div className="gatsby-highlight" data-language="text">
            <pre className={`language-text ${styles.commandArea}`}>
              <code className="language-text">
              {isInteractive && 
                <button className={styles.button} onClick={() => startStopOrReset()}>
                  {getStartButtonLabel(isPlaying, isFinalStep)}
                </button>
              }
                {formatCommands(isPlaying, steps, currentStep)}
                {
                  // commenting for now until this gets implemented
                  // <button className={`${styles.button} ${styles.right}`} onClick={() => setIsInteractive(!isInteractive)}>
                  // {isInteractive ? "diagram" : "interactive"}
                  // </button>
                }
              </code>
            </pre>
          </div>
      </div>
      <div className="gatsby-highlight" data-language={language}>
        <pre className={`language-${language}`}>
          <code className={`language-${language}`}>
            {isInteractive?  
              (isPlaying ? formatStep(steps[currentStep]) : start.text) :  
              (children)
             }
          </code>
        </pre>
      </div>
    </section>
  )
}

function formatStep({text, cursor}) {
  return (
    [...text].map((char, index) => {
      if (cursor === index) return <span key={index} className={styles.cursor}>{char}</span>
      return char
    })
  )
}

function formatCommands(isPlaying, steps, currentStep) {
  if (isPlaying) {
    return ( 
      steps.slice(0, currentStep+1)
           .map((step, index) => {
         if ( index === currentStep)
          return <span key={index} className={styles.activeCommand}>{step.command}</span>
         else  
          return step.command
        })
      )
  }
  return '';
}

function getStartButtonLabel(isPlaying, isFinalStep) {
  if (!isPlaying) return "Start";
  if (isFinalStep) return "Restart";
  return "Stop";
}
