// extracted by mini-css-extract-plugin
export var bookLink = "bookreview-module--book-link--75773";
export var bookReview = "bookreview-module--book-review--43f11";
export var bookReviewWide = "bookreview-module--book-review-wide--124ef";
export var bookshelf = "bookreview-module--bookshelf--0b276";
export var left = "bookreview-module--left--d7e77";
export var review = "bookreview-module--review--9c619";
export var right = "bookreview-module--right--20f47";
export var showTooltipAppear = "bookreview-module--show-tooltip-appear--cddca";
export var showTooltipEnter = "bookreview-module--show-tooltip-enter--01d36";
export var showTooltipEnterActive = "bookreview-module--show-tooltip-enter-active--f9029";
export var showTooltipEnterDone = "bookreview-module--show-tooltip-enter-done--64e34";
export var showTooltipExit = "bookreview-module--show-tooltip-exit--a0b9a";
export var showTooltipExitActive = "bookreview-module--show-tooltip-exit-active--eaf17";
export var showTooltipExitDone = "bookreview-module--show-tooltip-exit-done--8df8f";
export var stars = "bookreview-module--stars--c166b";
export var title = "bookreview-module--title--808d0";
export var tooltip = "bookreview-module--tooltip--5ed87";
export var withContent = "bookreview-module--with-content--f44ef";