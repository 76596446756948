import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import * as styles from './bookreview.module.css'

export const BookShelf = ({ children }) => {
  return <section className={styles.bookshelf}>{children}</section>
}

export const BookReview = ({ title, author, imgUrl, url, review, wide }) => {
  const imgAlt = `${title} book cover`
  const id = title
    .toLowerCase()
    .replace(/(,|:|\.)/g, '')
    .replace(/ /g, '-')

  const commonProps = {title, id, author, imgUrl, imgAlt, url, review};

  return (
    wide ? <WideBookReview {...commonProps}/> :
      <InlineBookReview {...commonProps} />
  )
}

const WideBookReview = ({title, imgUrl, imgAlt, url, author, review}) => (
  <section className={styles.bookReviewWide}>
    <section className={styles.left}>
      <a
        href={url}
        className={styles.bookLink}
        rel="nofollow"
        target="blank_"
        title={title}
        aria-label={`${title} by ${author}`}
      >
        <img src={imgUrl} alt={imgAlt} height="300" />
      </a>
    </section>
    <section className={styles.right}>
      <h3 className={styles.title}>
        {title}
        <br />
        <small>by {author}</small>
      </h3>
      <section className={`${styles.review} ${styles.withContent}`}>
        <StarsReview stars={review.stars} />
        <p>
          {review.content}
        </p>
      </section>
    </section>
  </section>
)

const InlineBookReview = ({title, id, imgUrl, imgAlt, url, author, review}) => (
    <section className={styles.bookReview}>
      <a
        href={url}
        className={styles.bookLink}
        rel="nofollow"
        target="blank_"
        title={title}
        aria-label={`${title} by ${author}`}
      >
        <img src={imgUrl} alt={imgAlt} height="300" />
        <h3 className={styles.title}>
          {title}
          <br />
          <small>by {author}</small>
        </h3>
      </a>
      {review && <Review id={id} {...review} />}
    </section>
)

export const Review = ({ id, content, stars }) => {
  if (content) {
    return <ContentReview id={id} content={content} stars={stars} />
  } else {
    // Reviews with no content arent' focusable because they're just text.
    // There's no interaction. A screen reader should be able to access the
    // text using screen reader navigation. A keyboard user doesn't need to
    // focus the element because it doesn't provide additional interaction
    return (
      <section className={styles.review}>
        <StarsReview stars={stars} />
      </section>
    )
  }
}

export const ContentReview = ({ id, content, stars }) => {
  const [isShown, setIsShown] = useState(false)
  const starsAriaLabel = stars === 1 ? `1 star` : `${stars} stars`

  // Reviews with content are focusable because interacting with them shows a
  // tooltip with additional information.
  // https://www.w3.org/TR/wai-aria-practices-1.1/
  return (
    <section
      className={`${styles.review} ${styles.withContent}`}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      onFocus={() => setIsShown(true)}
      onKeyDown={(e) => {
        if (e.key === 'Esc') {
          setIsShown(false)
        }
      }}
      onBlur={() => setIsShown(false)}
      tabIndex="0"
      aria-describedby={id}
    >
      <StarsReview stars={stars} ariaHidden={true} />
      {content && (
        <CSSTransition
          in={isShown}
          classNames={{
            appear: styles.showTooltipAppear,
            enter: styles.showTooltipEnter,
            enterActive: styles.showTooltipEnterActive,
            enterDone: styles.showTooltipEnterDone,
            exit: styles.showTooltipExit,
            exitActive: styles.showTooltipExitActive,
            exitDone: styles.showTooltipExitDone,
          }}
          timeout={300}
        >
          <aside
            id={id}
            className={styles.tooltip}
            //aria-live="polite"
            role="tooltip"
            aria-label={`Review: ${starsAriaLabel}. ${content}`}
          >
            {content}
          </aside>
        </CSSTransition>
      )}
    </section>
  )
}

export const StarsReview = ({ stars, ariaHidden }) => {
  const starsAriaLabel = stars === 1 ? `1 star` : `${stars} stars`
  const ariaLabel = ariaHidden ? '' : `Review: ${starsAriaLabel}`

  return (
    <header
      className={styles.stars}
      aria-hidden={ariaHidden}
      aria-label={ariaLabel}
    >
      {range(stars)
        .map((_) => '★')
        .join('')}
      {range(5 - stars)
        .map((_) => '☆')
        .join('')}
    </header>
  )
}

const range = (num) =>
  Array(num)
    .fill(0)
    .map((t, i) => i)
