import React from 'react'
import * as styles from './notes.module.css'
import AgnosticLink from '../AgnosticLink.js'

export const Notes = ({ children, wide }) => {
  const wideClass = wide ? styles.wide : '';
  return (
    <section className={styles.notes + ' ' + wideClass}>
      {children}
    </section >
  );

}
export const Note = ({ title, description, href }) => {
  return (
    <AgnosticLink href={href} className={styles.note}>
      <section>
        <h3>{title}</h3>
        <p>{description}</p>
      </section>
    </AgnosticLink>
  )
}
