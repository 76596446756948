import React from 'react'

export class ThreeJSCube extends React.Component {
  static defaultProps = {
    animated: false,
  }

  constructor(props) {
    super(props)
    this.loaded = false
    this.parent = React.createRef()
  }

  componentDidMount() {
    this.loadCube()
  }

  loadCube() {
    if (!window.THREE && !this.loaded) {
      setTimeout(() => this.loadCube(), 1000)
      return
    }

    this.loaded = true
    const el = this.parent.current
    const width = el.getBoundingClientRect().width
    const height = width

    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000)

    const renderer = new THREE.WebGLRenderer()
    renderer.setSize(width, height)
    el.appendChild(renderer.domElement)
    el.style.marginBottom = '1.5rem'

    const geometry = new THREE.BoxGeometry(1, 1, 1)
    const material = new THREE.MeshBasicMaterial({ color: 0xff00ff })
    const cube = new THREE.Mesh(geometry, material)
    scene.add(cube)

    camera.position.z = 5

    const { animated } = this.props
    function renderScene() {
      requestAnimationFrame(renderScene)
      if (animated) {
        cube.rotation.x += 0.01
        cube.rotation.y += 0.01
      }
      renderer.render(scene, camera)
    }
    renderScene()
  }

  render() {
    return <div ref={this.parent}></div>
  }
}
