import React, { useRef, useEffect } from 'react';
import * as styles from './roguelike.module.css';
import * as ROT from 'rot-js';

export const Roguelike = () => {
  const displayRef = useRef(null);

  useEffect(() => {
    if (displayRef.current) {
      initGame(displayRef.current);
    }
  }, []);

  const initGame = (displayContainer) => {
    const mapWidth = 64;
    const mapHeight = 24;
    const map = [];
    const freeCells = [];

    const display = new ROT.Display({ width: mapWidth, height: mapHeight, fontSize: 18 });
    displayContainer.appendChild(display.getContainer());

    const dungeonGenerator = new ROT.Map.Digger(mapWidth, mapHeight);
    dungeonGenerator.create((x, y, isWall) => {
      if (!map[x]) {
        map[x] = [];
      }
      map[x][y] = isWall;
      if (!isWall) {
        freeCells.push([x, y]);
      }
    });

    const player = new Player(...[...pluckCell(freeCells), display]);
    const zombies = [
      new Zombie(...[...pluckCell(freeCells), display]),
      new Zombie(...[...pluckCell(freeCells), display]),
      new Zombie(...[...pluckCell(freeCells), display]),
    ];

    function drawMap() {
      for (let x = 0; x < mapWidth; x++) {
        for (let y = 0; y < mapHeight; y++) {
          const cell = map[x][y] ? '#' : '.';
          display.draw(x, y, cell);
        }
      }
      player.draw();
      zombies.forEach(zombie => zombie.draw());
    }

    drawMap();

    function handleInput(event) {
      let newX = player.x;
      let newY = player.y;

      switch (event.keyCode) {
        case ROT.KEYS.VK_UP:
          newY--;
          break;
        case ROT.KEYS.VK_DOWN:
          newY++;
          break;
        case ROT.KEYS.VK_LEFT:
          newX--;
          break;
        case ROT.KEYS.VK_RIGHT:
          newX++;
          break;
        default:
          return;
      }

      if (!map[newX][newY]) {
        player.x = newX;
        player.y = newY;
      }

      drawMap();
    }

    document.addEventListener('keydown', handleInput);

    return () => {
      document.removeEventListener('keydown', handleInput);
    };
  };

  return (<div ref={displayRef} class={styles.roguelike}></div>)
};

class Entity {
  constructor(x, y, symbol, display) {
    this.x = x;
    this.y = y;
    this.symbol = symbol;
    this.display = display;
  }

  draw() {
    this.display.draw(this.x, this.y, this.symbol);
  }
}

class Player extends Entity {
  constructor(x, y, display) {
    super(x, y, '@', display);
  }
}

class Zombie extends Entity {
  constructor(x, y, display) {
    super(x, y, 'z', display);
  }
}

/*
* Plucks a random cell from a collection of cells and removes it from the collectin of cells.
*/
function pluckCell(cells) {
  const cell = ROT.RNG.getItem(cells);
  cells.splice(cells.indexOf(cell));
  return cell;
}
